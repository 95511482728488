import './index.scss'

$('.footer .right-nav .item .item-title .iconfont').on('click',function(){
  let $parent = $(this).closest('.item')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $('.footer .right-nav .item').removeClass('active').find('.sub-nav').slideUp()
    $parent.addClass('active').find('.sub-nav').slideDown()
  }
})

$('footer .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})
$(document).ready(function(){
  $('.footer a').not(':contains("发展历程"), :contains("企业文化")').attr('href', function(i, value) {
      return value.split('#')[0];
  });
});