import './index.scss'

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  if($('.ny-banner .bg-img b').length > 0){
    $('.ny-banner .bg-img b').css('transform','translateY('+ scroH / 4 +'px)')
  }
}); 

var bannerNavW = 0
$(document).ready(function(){
  if($('.ny-banner .nav').length > 0){
    let paddingLeft = Number($('.ny-banner .nav ul').css('padding-left').split("p")[0])
    let paddingRight = Number($('.ny-banner .nav ul').css('padding-right').split("p")[0])
    bannerNavW = bannerNavW+paddingLeft+paddingRight
    if($(window).width() >= 768){
      let item = $('.ny-banner .nav ul li')
      item.each(function(item){
        bannerNavW += $(this).outerWidth()
      })
      $('.ny-banner .nav').css('width', bannerNavW +'px');
    }else{
      $('.ny-banner .nav').css('width', '100%');
    }
  }

  $('[data-open="nymenu"]').on('click', function () {
    if($('.nymenu-icon .hamburger').hasClass('active')){
      $('.ny-banner .nymenu-icon').addClass('close-status')
      $('.nymenu-icon .hamburger').removeClass('active')
      $('.ny-banner .nav').css('width','0px');
    }else{
      $('.ny-banner .nymenu-icon').removeClass('close-status')
      $('.nymenu-icon .hamburger').addClass('active')
      $('.ny-banner .nav').css('width', bannerNavW +'px');
    }
  })
})

$(window).resize(function(){
  setTimeout(function(){
    bannerNavW = 0
    let paddingLeft = Number($('.ny-banner .nav ul').css('padding-left').split("p")[0])
    let paddingRight = Number($('.ny-banner .nav ul').css('padding-right').split("p")[0])
    bannerNavW = bannerNavW + paddingLeft + paddingRight
    if($(window).width() >= 768){
      let item = $('.ny-banner .nav ul li')
      item.each(function(item){
        bannerNavW += $(this).outerWidth()
      })
      $('.ny-banner .nav').css('width', bannerNavW +'px');
    }else{
      $('.ny-banner .nav').css('width', '100%');
    }
  },500)
})


