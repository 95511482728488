module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner">\r\n  <div class="bg-img">\r\n    <b style="background-image: url(' +
((__t = (require('../../assets/images/banner_about.jpg'))) == null ? '' : __t) +
');"></b>\r\n  </div>\r\n  <div class="banner-text">\r\n    <div class="left">\r\n      <h2>About Us</h2>\r\n      <h1>关于我们</h1>\r\n    </div>\r\n    <div class="right">\r\n      <div class="fixed-cont">\r\n        <div class="nav">\r\n          <ul>\r\n            <li><a href="#">企业简介</a></li>\r\n            <li class="active"><a href="#">发展历程</a></li>\r\n            <li><a href="#">业务发展</a></li>\r\n            <li><a href="#">荣誉资质</a></li>\r\n            <li><a href="#">企业文化</a></li>\r\n          </ul>\r\n        </div>\r\n        <div class="nymenu-icon" data-open="nymenu">\r\n          <div class="hamburger hamburger-8 active">\r\n            <div></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="common-scroll-icon">\r\n    <div class="icon1"></div>\r\n  </div>\r\n</section>';

}
return __p
}