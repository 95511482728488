import './index.scss'
import '../../assets/scripts/smoothscroll'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .menu-icon').hasClass('active')){
    $('header .menu-icon').removeClass('active')
    $('header .hamburger').removeClass('active')
    $('header .mob-nav').slideUp(400)
    setTimeout(function() {
      $('header .bg').removeClass('nav-show-mob')
    }, 400);
  }else{
    $('header .menu-icon').addClass('active')
    $('header .hamburger').addClass('active')
    $('header .bg').addClass('nav-show-mob')
    $('header .mob-nav').slideDown(400)
  }
})

$('header .mob-nav .nav-title .iconfont').on('click',function(){
  let $parents = $(this).closest('li')
  if($parents.hasClass('show-nav')){
    $parents.removeClass('show-nav').find('.sub-nav').slideUp()
  }else{
    $parents.addClass('show-nav').find('.sub-nav').slideDown()
    $parents.siblings().removeClass('show-nav').find('.sub-nav').slideUp()
  }
})

$('.header .right .open-search').on('click',function(){
  $('header').addClass('show-search')
  $('.header .search form').css('transform','scaleX(1)')
})

$('.header .search .close-search').on('click',function(){
  $('header').removeClass('show-search')
  setTimeout(function(){
    $('.header .search form').css('transform','scaleX(0)')
  },300)
})

var mouseover_tid = [];
var mouseout_tid = [];
var subNavH = 0
var oldWinW = $(window).width()
$(document).ready(function(){
  var headerHold = $('.header').outerHeight()

  if(window.location.href.indexOf('#md') != -1){
    if($('.ny-banner').length > 0){
      let h = $('.ny-banner').outerHeight()
      $(document).scrollTop(h)
    }
  }
  if($(window).width() > 1024){
    $('.header .nav .sub-nav').each(function(index,item){
      if($(item).outerHeight() > subNavH){
        subNavH = $(item).outerHeight()
      }
    })
    $('.header .nav .sub-nav').css('height',subNavH +'px')
  }
  //鼠标经过显示子菜单
  $(".header .nav").each(function (index) {
    headerHold = $('.header').outerHeight()
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          $('.header .nav .sub-nav').slideDown()
          $('header').addClass('show-bg')
          $('.header .bg').css('height',(headerHold + subNavH) +'px')
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
          $('.header .nav .sub-nav').slideUp()
          setTimeout(function(){
            // $('.header .bg').css('height',(headerHold) +'px')
            $('.header .bg').css('height','100%')
          },100)
          setTimeout(function(){
            $('header').removeClass('show-bg')
          },300)
        }, 201);
    });
  });

  $('.header a').not(':contains("发展历程"), :contains("企业文化")').attr('href', function(i, value) {
      return value.split('#')[0];
  });
  $('.ny-banner .banner-text .right a').not(':contains("发展历程"), :contains("企业文化")').attr('href', function(i, value) {
      return value.split('#')[0];
  });
  var currentURL = window.location.pathname;
  $('.ny-banner .banner-text .right a').each(function() {
      var fullHref = $(this).attr('href');
      if (fullHref === currentURL) {
        $(this).attr('href', 'javascript:;');
        $(this).on('click', function () {
            if($('.ny-banner').length > 0){
            let h = $('.ny-banner').outerHeight()
            console.log('hhhh',h);
            $('html, body').animate({scrollTop: h}, 'slow');
        }
        })
      }
  });
  var scroll = new SmoothScroll('a[href*="#"]', {
      speed: 500,
      speedAsDuration: true,
      offset: 0
  });
})

let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度

  if (scroH > 200) {
    //距离顶部大于200px时
    $('header').addClass('scroll')
  }else{
    $('header').removeClass('scroll')
  }

  if (scroH > viewH / 2) {
    //距离顶部大于200px时
    $('footer .side-btn').addClass('show-l')
  }else{
    $('footer .side-btn').removeClass('show-l')
  }

  var footerH = $('footer').outerHeight()

  if (contentH - (scroH + viewH) <= footerH - 60) { 
    $('footer .side-btn').addClass('abs')
  }else{
    $('footer .side-btn').removeClass('abs')
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0) {
    scroH > 10 ? $('header').addClass('tran') : $('header').removeClass('tran') 
  }
  if (delta < 0) {
    $('header').removeClass('tran')
  }

  beforeScroH = scroH;
});

$(window).resize(function(){
  // location.reload()
  let nowWinW = $(window).width()
  if(oldWinW <= 1024 && nowWinW > 1024){
    location.reload()
  }
})
